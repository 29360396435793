type SingleRepoItem = {
  query: {
    slug: string;
  };
  data: never;
  identifier: string;
};

type MultipleRepoItem = {
  query: never;
  data: never[];
  identifier: string;
  total: number;
};

export type SingleRepo = SingleRepoItem[];
export type MultipleRepo = MultipleRepoItem[];

export enum LoadingStates {
  IS_BLOGS_LOADING = 'isBlogsLoading',
  IS_BLOG_LOADING = 'isBlogLoading',
}

export enum RepoStates {
  BLOGS_REPO = 'blogsRepo',
  SINGLE_BLOG_REPO = 'singleBlogRepo',
  PROPERTIES_REPO = 'propertiesRepo',
  SINGLE_PROPERTY_REPO = 'singlePropertyRepo',

  PROPERTIES_GRID_REPO = 'propertiesGridRepo',
}

export type States = RepoStates | LoadingStates;

export enum FetchModes {
  INIT = 'init',
  SEARCH = 'search',
  LOAD_MORE = 'load-more',
  SORT = 'sort',
  LOAD_BY_DATA = 'load-by-data',
}

export enum ComponentMode {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum SessionKeys {
  SESSION = 'session',
  CONTACT_ID = 'contact_id',
  KICKBACK_LOG_ID = 'kickback_log_id',

  HIGHLIGHTED_PROPERTIES_CRITERIA = 'highlightedPropertiesCriteria',
}

export enum ValidQueryKeys {
  CONTACT_ID = 'contact_id',
  STATE = 'state',
}

export type SessionStore = {
  session?: string | null;
  contact_id?: number | null;
  highlightedPropertiesCriteria?: string | null;
};

export enum FullScreenModalTypes {
  GALLERY = 'gallery',
  SINGLE = 'single',
}

export type FullScreenModalType = FullScreenModalTypes.SINGLE | FullScreenModalTypes.GALLERY;

export interface FullScreenModalConfig {
  is_active: boolean;
  item: null;
  type: FullScreenModalType;
}
