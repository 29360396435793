import { defineStore } from 'pinia';
import { RequestMethod, RequestName, RequestProxy, useCaller } from '~/hydration/bridges';
import { useContactsStore } from '~/units/contacts/store';
import { ActivityTypes } from '~/units/polaris/constants';

export const usePolarisStore = defineStore({
  id: 'polaris',
  state: () => {
    return {};
  },
  actions: {
    async init() {
      const contactsStore = useContactsStore();
      if (!contactsStore.neuronSessionId && contactsStore.contactInfo?.contact_id) {
        await useCaller(RequestProxy.POLARIS, RequestName.SEND_PING, 'ping', RequestMethod.POST, {}, {});
      }
    },

    async sendActivity(
      activityTypeId: ActivityTypes,
      data: {
        page_id: string;
      },
    ) {
      const contactsStore = useContactsStore();
      if (contactsStore.neuronSessionId) {
        await useCaller(
          RequestProxy.POLARIS,
          RequestName.SEND_ACTIVITY,
          'activities',
          RequestMethod.POST,
          {},
          {
            activity_type_id: activityTypeId,
            page_id: data.page_id,
          },
        );
      }
    },
  },
  getters: {},
});
