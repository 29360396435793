import { defineStore } from 'pinia';
import { LayoutTypes, MenuTypes } from '~/constants/ui.constants';
import type { Colors, Company, Component, Menu, Page, Sitemap, ThemeDesign, ThemeDesignLayout } from '~/types';

import type { GoogleTag, Theme } from '~/units/website/types';
import type { MenuLink } from '~/ts-shim';
import { CompanyKeys } from '~/units/website/constants';

export const useWebsiteStore = defineStore({
  id: 'website',
  state: (): {
    company: Company | null;
    layout: string | null;
    theme: Theme | null;

    menus: Menu[];
    layouts: ThemeDesignLayout[];

    themeDesign: ThemeDesign | null;

    companyInternalColors: Colors;

    pages: Page[];

    googleTags: GoogleTag[];
  } => {
    return {
      company: null,

      // @deprecated use theme instead of layout
      layout: null,
      theme: null,

      menus: [],
      layouts: [],

      themeDesign: null,

      companyInternalColors: {},

      pages: [],

      googleTags: [],
    };
  },
  actions: {
    setCompany(data: Company) {
      this.company = data;
      this.companyInternalColors = data?.colors;
      this.setGoogleTags();
    },

    setTheme(theme: Theme | null) {
      this.layout = theme;
      this.theme = theme;
    },

    __setMenus(menus: Menu[]) {
      this.menus = menus;
    },

    __setLayouts(layouts: ThemeDesignLayout[]) {
      this.layouts = layouts;
    },

    __setThemeDesign(themeDesign: ThemeDesign | null) {
      this.themeDesign = themeDesign;
    },

    __setPages(pages: Page[]) {
      this.pages = pages;
    },

    setSitemap(sitemap: Sitemap) {
      this.__setPages(sitemap.pages || []);
      this.__setMenus(sitemap?.menus || []);
      this.__setLayouts(sitemap?.layouts || []);
      this.__setThemeDesign(sitemap?.theme_design || null);
    },

    setGoogleTags(): void {
      if (this.companyKey === CompanyKeys.BYDESIGN_HOMES) {
        this.googleTags = [
          {
            name: 'ads',
            id: 'AW-10990438948',
          },
          {
            name: 'analytic',
            id: 'G-ZW1TCENK7F',
          },
          {
            name: 'neuron-analytic',
            id: 'G-9T8W7D8ZTR',
          },
        ];
      } else if (this.companyKey === CompanyKeys.BROWNS_ESTATE_AGENTS) {
        this.googleTags = [
          {
            name: 'neuron-analytic',
            id: 'G-P855SPR7F5',
          },
        ];
      } else if (this.companyKey === CompanyKeys.ALLEN_RESIDENTAL) {
        this.googleTags = [
          {
            name: 'neuron-analytic',
            id: 'G-9XJETG6D9Y',
          },
        ];
      }
    },

    calculateMaxRowHeight(componentRef, className) {
      const children = componentRef.children;
      let maxHeight = 0;
      for (let child of children) {
        const featureTextElement = (() => {
          const element = child.getElementsByClassName(className);
          if (element && element[0]) {
            return element[0];
          }
          return null;
        })();
        if (featureTextElement) {
          const height = featureTextElement.scrollHeight;
          if (height > maxHeight) {
            maxHeight = height;
          }
        }
      }
      return maxHeight;
    },
  },
  getters: {
    currentPage(): Page | undefined | null {
      const route = useRoute();
      const pages = useState('pages').value;
      if (pages && Array.isArray(pages)) {
        if (route.params.personalised_page_id) {
          return pages.find((page: any) => page._id === route.params.personalised_page_id);
        }
        const page = pages.find((page: any) => page._id === route.meta.pageId);
        if (page && !route.meta.personalisedPageId) {
          return page;
        } else if (page && page.personalised_pages) {
          return page.personalised_pages.find((pPage: any) => pPage._id === route.meta.personalisedPageId);
        }
        return page;
      }
      return null;
    },

    headerMenuItems(state): MenuLink[] {
      return state.menus.find((menu) => menu.type === MenuTypes.HEADER)?.items || [];
    },

    footerMenuItems(state) {
      return state.menus.find((menu) => menu.type === MenuTypes.FOOTER)?.items || [];
    },

    headerLayout(state): ThemeDesignLayout | null | undefined {
      if (state.layouts) {
        return state.layouts.find((layout) => layout.layout.name === LayoutTypes.HEADER);
      }
      return null;
    },

    headerMenuComponents(): Component[] {
      if (this.currentPage && this.currentPage.header && this.currentPage.header.length) {
        return this.currentPage.header;
      }
      if (this.headerLayout && this.headerLayout.components) {
        return this.headerLayout.components;
      }
      return [];
    },

    propertiesConfig() {
      if (this.theme === 'theme3') {
        const defaultValues = {
          isSeparatedPriceSelections: true,
          backgroundColor: '',
          advancedSearchPopupStyle: 'background-color: var(--isabelline); top: 328px;',
          advancedSearchMobilePopupBackgroundClass: '!tw-bg-[var(--isabelline)]',
        };
        if (this.company?.company_key === CompanyKeys.BROWNS_ESTATE_AGENTS) {
          return defaultValues;
        } else if (this.company?.company_key === CompanyKeys.NEIL_KING) {
          return {
            ...defaultValues,
            advancedSearchPopupStyle: 'background-color: var(--isabelline); top: 368px;',
          };
        }
        return defaultValues;
      }

      return {
        isSeparatedPriceSelections: false,
        backgroundColor: 'background-color: var(--white);',
        advancedSearchPopupStyle: 'background-color: var(--white);',
        advancedSearchMobilePopupBackgroundClass: '',
      };
    },

    companyKey(state) {
      return state.company?.company_key;
    },

    cssVariables(state) {
      const cssVariables: { [key: string]: string | number | null | undefined | boolean } = {};
      if (this.colors) {
        Object.keys(this.colors).forEach((key) => {
          cssVariables[`--${key}`] = this.colors[key];
        });
      }

      const colorKeys = ['primary_color', 'secondary_color', 'tertiary_color'];
      colorKeys.forEach((colorKey) => {
        if (state.company && state.company[colorKey]) {
          cssVariables[`--${colorKey}`] = state?.company[colorKey];
        }
      });

      return {
        ...cssVariables,

        // no need to have these variables as in top, they already injected to css variables object
        '--akya-text-color': this.colors['akya-text-color'],
        '--akya-background-color': this.colors['akya-background-color'],
        '--akya-confirm-button-text-color': this.colors['akya-confirm-button-text-color'],
        '--akya-confirm-button-background-color': this.colors['akya-confirm-button-background-color'],
      };
    },

    sidebarComponents(): Component[] {
      if (this.currentPage && this.currentPage.sidebar) {
        return this.currentPage.sidebar;
      }
      return [];
    },

    googleTagsScripts(state) {
      return state.googleTags.map((tag) => {
        return {
          key: tag.name,
          type: 'text/javascript',
          src: `https://www.googletagmanager.com/gtag/js?id=${tag.id}`,
          async: !tag.sync,
        };
      });
    },
    colors(state) {
      const colors = state.companyInternalColors;
      try {
        if (colors) {
          return {
            ...colors,
          };
        }
      } catch (e) {}
      return {};
    },

    isOldStructure(state) {
      return ['theme2', 'theme3'].includes(state.theme);
    },
  },
});
